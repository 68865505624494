@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

$color_blue: #1A5AB1; //blue
//$color_blue: #FFA500; //orange
$color_white: #fff;
$color_black: #0D1A2A; //Dark Blue Background Home Page 

$color_grey: #808080;
$color_light: rgba(255, 255, 255, 0.8);
$color_light_grey: #eff2f6;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-weight: 300;
  line-height: 1.6;
}
.header {
  transition: all 0.3s ease-in-out;
  background-color: $color_black;
  padding: 0.8rem 0;

  .logo-div {
    .logo {
      max-width: 90px;
      transition: all 0.3s ease-in-out;
    }
    .logo-alt {
      max-width: 70px;
      transition: all 0.3s ease-in-out;
    }

    @media all and (max-width: 768px) {
      .logo {
        max-width: 45px;
      }
      .logo-alt {
        max-width: 40px;
      }
    }
  }

  .nav-links {
    display: flex;
    align-items: center;
    ul {
      padding: 0;
      margin: 0;
      margin-right: 1rem;
      list-style: none;
      li {
        margin: 0 1rem;
        padding: 1.3rem 0;
        a {
          position: relative;
          color: $color_light;
          text-decoration: none;
          font-size: 0.9rem;
          font-weight: 400;
          letter-spacing: 1px;
          transition: all 0.3s ease-in-out;
          .dropdown-icon {
            margin-left: 0.1rem;
            font-size: 0.85rem;
            font-weight: bold;
            color: $color_light;
          }

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 90%;
            height: 0.1rem;
            background-color: $color_white;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            color: $color_white;
            &::before {
              transform: scaleX(1);
            }
            .dropdown-icon {
              color: $color_white;
            }
          }
        }
      }

      @keyframes links {
        from {
          padding: 2rem 0.7rem;
        }
        to {
          padding: 1.3rem 0.7rem;
        }
      }

      .custom-dropdown {
        position: relative;
        ul {
          padding: 1.3rem 0.7rem;
          margin: 0;
          list-style: none;
          position: absolute;
          top: 102%;
          left: -10px;
          background-color: $color_white;
          min-width: 250px;
          border-radius: 0.8rem;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          display: none;
          opacity: 0;
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          
          li {
            padding: 0.5rem 0;
            a {
              position: relative;
              color: $color_black;
              font-size: 1rem;
              letter-spacing: normal;
              font-weight: 600;
              min-height: 2rem;
              &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0.1rem !important;
                background-color: $color_blue;
                transform: scaleX(0);
                transform-origin: left;
                transition: all 0.3s ease-in-out;
              }

              &:hover {
                &::before {
                  transform: scaleX(1);
                  height: 0.1rem;
                }
              }
            }
            .active {
              &::before {
                transform: scaleX(1);
              }
            }
          }

          &:hover {
            display: block;
            opacity: 1;
          }
        }

        &:hover {
          ul {
            display: block;
            opacity: 1;
            animation: links 0.2s ease-in-out;
          }
        }
      }
    }
  }

  @media all and (max-width: 992px) {
    .nav-links {
      display: none;
    }
  }
}
.scrolled {
  background-color: $color_white;
  padding: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  ul {
    li {
      a {
        color: #000 !important;
        .dropdown-icon {
          color: #000 !important;
        }

        &::before {
          background-color: $color_blue !important;
        }
      }
    }
  }
}
.links-color {
}
.banner {
  min-height: 100vh;
  background-color: $color_black;
  display: flex;
  align-items: center;
  padding-top: 5rem;
  @media all and (max-width: 992px) {
    padding-top: 4rem;
  }
}

.banner-img{
  max-width: 530px;
}
.hero_heading {
  color: $color_white;
  font-size: 3rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 1.0rem;
  span {
    color: $color_blue;
    text-transform: uppercase;
  }

  @media all and (max-width: 768px) {
    font-size: 2.2rem;
  }
}

.white-para {
  color: $color_light;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5rem;
  max-width: 500px;
  margin: 0;
}

.banner-btn {
  background-color: $color_blue;
  color: $color_white;
  padding: 0.7rem 1rem;
  border-radius: 1.5rem;
  text-decoration: none;
  max-width: fit-content;
  .btn-icon {
    font-size: 1.4rem;
    margin-right: 0.5rem;
    transition: all 0.3s ease-in-out;
    color: $color_white;
  }
  &:hover {
    .btn-icon {
      margin-left: 0.4rem;
    }
  }
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animated-heading {
  min-height: 5rem;
  display: flex;
  align-items: center;
}
.dynamic-heading {
  color: $color_white;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.hamburger {
  position: relative;
  width: 1.5rem;
  height: 1rem;
  margin: 1rem 0;
  span {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $color_white;
    transition: all 0.3s ease-in-out;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-child(3) {
      bottom: 0;
      width: 50%;
    }
  }

  @media all and (min-width: 992px) {
    display: none;
  }
}

.hamburger-scrolled {
  span {
    background-color: $color_black;
  }
}

.home-overview {
  padding: 4.5rem 0;

  @media all and (max-width: 992px) {
    padding: 2rem 0;
  }
}

.left-icon {
  max-width: 60px;
}
.right-content {
  margin-left: 6.6rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      // margin-bottom: 0.5rem;
      position: relative;
      padding-left: 1.5rem;

      .li-icon {
        content: "";
        position: absolute;
        left: 0;
        top: 0.5rem;
        width: 0.8rem;
        height: 0.8rem;
        background-color: $color_blue;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          color: $color_white;
          font-size: 0.7rem;
        }
      }
    }
  }

  @media all and (max-width: 768px) {
    margin-left: 0.5rem;
    margin-top: 1rem;
  }
}

.our-approach {
  padding: 4rem 0;
  background-color: $color_light_grey;
  @media all and (max-width: 992px) {
    padding: 2rem 0;
  }
}

.approach-content {
  max-width: 304px;

  @media all and (max-width: 992px) {
    max-width: 100%;
  }
}

.approach-img {
  width: 75%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
}
.our-services {
  padding: 3rem 0;
  background-image: url("./img/data-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  min-height: 100vh;
  @media all and (max-width: 992px) {
    padding: 2rem 0;
  }
}

.static-portion {
  position: relative;
  padding-top: 0rem;
}

.service-card {
  background-color: $color_light_grey;
  padding: 3rem 1.5rem;
  border-radius: 0.6rem;
  display: flex;
 
  .ser-icon {
    flex: 1;
    text-align: center;
    img {
      max-width: 80px;
    }
  }
  .serv-content {
    
    flex: 5;
    .inner {
      @media all and (min-width: 992px) {
        max-width: 600px;
      }
    }
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 1rem;
    .ser-icon {
      margin-bottom: 1rem;
      text-align: left;
    }
  }
}

.service-btn {
  background-color: $color_blue;
  color: $color_white;
  padding: 0.4rem 1rem;
  font-size: 0.9rem;
  border-radius: 1.5rem;
  text-decoration: none;
  max-width: fit-content;
  border: 0;

  .btn-icon {
    font-size: 1.4rem;
    margin-right: 0.5rem;
    transition: all 0.3s ease-in-out;
    color: $color_white;
  }
  &:hover {
    .btn-icon {
      margin-left: 0.4rem;
    }
  }
}
.static-card {
  position: sticky;
  top: 10rem;
  .card-inner {
    position: relative;

    a {
      text-decoration: none;
      font-size: 1.2rem;
      color: $color_grey;
      margin-bottom: 1rem;
      display: block;
      padding: 0.5rem 0 0.5rem 1rem;
    }
    .active {
      color: $color_black !important;
      border-left: 2px solid $color_blue;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: #cbcbcb;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
}

.portfolio {
  min-height: 100vh;
  background-color: $color_light_grey;
  padding: 6rem 0;
 
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  @media all and (max-width: 992px) {
    padding: 3rem 0;
  }

  @media all and (max-width: 768px) {
    background-image: none;
  }
}

.black-area {
  margin-top: 2rem;
  background-color: $color_black;
  border-radius: 0.6rem;
  padding: 3rem 0;
}
.white {
  color: $color_white;
}

.acc-card {
  padding: 1rem 2rem;
  position: relative;
  border-right: 1px solid $color_grey;

  .acc-img {
    img {
      max-width: 150px;
    }
  }

  .acc-link {
    width: 2.1rem;
    height: 2.1rem;
    border: 1px solid $color_white;
    border-radius: 50%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    &:hover {
      padding: 0.5rem;
      background-color: $color_white;
      .icon {
        color: $color_black;
      }
    }
    a {
      text-decoration: none;
      .icon {
        color: $color_white;
        font-size: 2rem;
      }
    }
  }

  @media all and (max-width: 768px) {
    border-right: 0;
    border-bottom: 1px solid $color_grey;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;

    .acc-link {
      margin-top: 2rem;
    }
  }
}
.acc-card-last {
  border: 0;
}
.contact {
  padding: 2rem 0;
  // background-image: url("./img/form-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  @media all and (max-width: 992px) {
    padding: 2rem 0;
  }
}
.custom-field {
  outline: none;
  border: 0;
  border: 2px solid $color_white;
  border-bottom: 1px solid $color_grey;
  padding: 0.5rem;
  padding-bottom: 0.6rem;
  width: 100%;
  font-size: 0.9rem;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  &:focus {
    border: 2px solid $color_blue;
    border-bottom: 1px solid $color_grey;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}

.footer {
  min-height: 20vh;
  position: relative;
  background-color: $color_black;
  padding: 0.2rem 0;
  padding-top: 3rem;
  @media all and (max-width: 992px) {
    padding: 2rem 0;
    padding-top: 2rem;
  }
}
.footer-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    margin-bottom: 0.3rem;
    a {
      color: $color_white;
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      font-weight: 300;
      font-size: 0.9rem;
      &:hover {
        color: $color_light;
      }
    }
  }
}
.small {
  font-size: 0.9rem;
}
.email {
  a {
    color: $color_white;
    text-decoration: none;
    padding-left: 0.5rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: $color_light;
    }
  }
}

.server-banner {
  // background-image: url("./img/service-bg.png");
  background-color: $color_black;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 2rem 0;
  @media all and (max-width: 992px) {
    padding-top: 2rem;
  }
}
.service-img {
  max-width: 500px;
  width: 100%;
}

.service-banner-cards {
  margin-top: -6rem;
  min-height: 30vh;
  .bottom-card {
    background-color: $color_white;
    padding: 2rem 1.6rem;
    border-radius: 0.7rem;
    min-height: 200px;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      margin-top: -15px;
    }
  }
  @media all and (max-width: 992px) {
    margin-top: -2rem;
    .bottom-card {
      min-height: auto;
    }
  }

  @media all and (min-width: 993px) and (max-width: 1400px) {
    .bottom-card {
      padding: 1rem;
    }
  }
}
.service-common {
  padding: 4rem 0;

  .side-card {
    background-color: $color_black;
    border-radius: 0.6rem;
    padding: 2.5rem;
    height: 100%;
  }

  .service-com-card {
    border-radius: 0.6rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 1.5rem 2.5rem;

    .service-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: 1.5rem 0;
      .service-icon {
        width: 100px;
        margin-right: 2rem;
      }
    }
  }
  @media all and (max-width: 992px) {
    padding: 3rem 0;
  }
}
.service-common-odd {
  background-color: $color_light_grey;
}

.service-item-last {
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.service-heading {
  margin-top: 2rem;
  margin-bottom: -3rem;
}

.why-acc {
  padding: 6rem 0;

  @media all and (max-width: 992px) {
    padding: 3rem 0;
  }
}

.approach-card {
  background-color: $color_white;
  border-radius: 0.6rem;
  padding: 2rem 2.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media all and (max-width: 768px) {
    padding: 1rem;
  }
}

.slick-card {
  background-color: $color_white;
  border-radius: 0.6rem;
  padding: 2rem 2.5rem;
  min-height: 430px;
  user-select: none;
  cursor: grab;
  @media all and (max-width: 768px) {
    padding: 1rem;
    min-height: 500px;
  }
}

.approach {
  padding-right: 2rem;
  @media all and (max-width: 992px) {
    padding-right: 0;
  }
}



.react-multiple-carousel__arrow {
  background-color: rgba(0, 72, 231, 0.5);
  &:hover {
    background-color: rgba(0, 72, 231, 1);
  }
}

.deployment {
  background-color: $color_blue;
  padding: 2rem;
  color: $color_white;
  border-radius: 0.7rem;
}

.bottom {
  background-color: $color_light_grey;

  .top-minus {
    bottom: -50px;
    width: fit-content;
    z-index: 99;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background-color: $color_blue;

    padding: 3rem;
    color: $color_white;
    border-radius: 0.7rem;
    @media all and (max-width: 768px) {
      width: 95%;
      padding: 1.5rem;
    }
  }
}
.contact-us {
  padding: 0.7rem 1rem;

  border-radius: 5rem;
  text-decoration: none;
  background-color: $color_black;
  color: $color_white;
  transition: all 0.3s ease-in-out;
  .btn-icon {
    font-size: 1.4rem;
    margin-right: 0.5rem;
    transition: all 0.3s ease-in-out;
    color: $color_white;
  }
  &:hover {
    .btn-icon {
      margin-left: 0.4rem;
    }
  }
}
.lens-feature-img {
  max-width: 80px;
}

.padding-last {
  padding-bottom: 2rem;
  @media all and (max-width: 768px) {
    padding-bottom: 17rem;
  }
}

.navigation-pils {
  list-style: none;
  padding: 0;
  margin: 1.4rem 0;
  display: flex;
  justify-content: center;

  li {
    margin-right: 2rem;
    button {
      border: 0;
      background-color: rgb(218, 224, 235);
      padding: 0.5rem 2rem;
      border-radius: 5rem;
      color: $color_black;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: rgba(218, 224, 235, 0.5);
        color: $color_black;
      }
    }
    .active {
      background-color: $color_blue;
      color: $color_white;
      &:hover {
        background-color: $color_blue;
        color: $color_white;
      }
    }
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    li {
      margin-right: 0;
      margin-bottom: 1rem;
      button {
        width: 200px;
      }
    }
  }
}

.tab-card-ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    padding-left: 1.5rem;
    .li-icon {
      content: "";
      position: absolute;
      left: 0;
      top: 0.5rem;
      width: 0.8rem;
      height: 0.8rem;
      background-color: $color_blue;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        color: $color_white;
        font-size: 0.7rem;
      }
    }
  }
}

.company-banner {
  min-height: 100vh;
  background-position: top;
  padding-top: 8rem;
}
.company-img {
  max-height: 300px;
}

.blue {
  color: $color_blue;
}
.partner-card {
  border-radius: 0.6rem;
  padding: 2.5rem 2rem;
  border-radius: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: $color_light_grey;
  }

  img {
    max-height: 75px;
  }
  @media all and (max-width: 768px) {
    padding: 1rem;
  }
}

.contact-card {
  border-radius: 0.6rem;
  padding: 2.5rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.perk-card {
  background-color: $color_light_grey;
  border-radius: 0.6rem;
  padding: 2.5rem 1.5rem;
  max-width: 235px;
  margin-right: 1rem;
  margin-top: 1rem;
  img {
    width: 70px;
  }
}

.company-env {
  padding: 8rem 0;
  background-image: url("./img/company-env-bg.svg");
  min-height: 80vh;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @media all and (max-width: 768px) {
    padding: 4rem 0;
  }
}
.carousel-image {
  img {
    border-radius: 0.6rem;
  }
}

.testimonial {
  padding: 5rem 0;
  background-image: url("./img/testimonials-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 70vh;
  @media all and (max-width: 768px) {
    padding: 4rem 0;
  }
}

.test-card {
  padding: 2.5rem 1.5rem;

  border-radius: 0.6rem;
  img {
    border-radius: 0.6rem;
  }
}

.test-border {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.6rem;
}
.test-content {
  position: relative;
  .inverted-comma {
    position: absolute;
    left: 0;
    top: -50px;
    font-size: 40px;
    color: $color_blue;
  }
}

.search {
  background-color: $color_light_grey;
  padding: 2rem;
  margin-top: 2rem;
}

.search-input {
  border: 1px solid #d1cece;
  background-color: $color_white;
  padding: 0.6rem 1rem;
  width: 100%;
  transition: all 0.3s ease-in-out;

  &:focus {
    outline: none;
    background-color: $color_light_grey;
  }
}
.search-select {
  border: 1px solid #d1cece;
  background-color: $color_white;
  padding: 0.6rem 1rem;

  transition: all 0.3s ease-in-out;
}

.search-btn {
  background-color: $color_blue;
  min-width: 200px;
  border-radius: 3rem;
  border: 0;
  padding: 0.55rem 1rem;
  margin-left: 1rem;
  color: $color_white;
  transition: all 0.3s ease-in-out;
  .search-icon {
    font-size: 1.5rem;
    color: $color_white;
  }
}
.clear{
  background-color: $color_blue;
  font-size: 0.9rem;
  border-radius: 0.4rem;
  border: 0;
  padding: 0.55rem 1rem; 
  margin-top: 1rem;
  color: $color_white;
  transition: all 0.3s ease-in-out;
 
}
.job {
 
  border: 1px solid #d1cece;
}
.job-desc{
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  a{
    text-decoration: none;
    background-color: $color_blue;
    color: $color_white;
    padding: 0.5rem 1rem;
  }
}
.location{
  padding: 1rem;
  border-top: 1px solid #d1cece;
}

.story-card{
  background-color: $color_white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 0.4rem;
  overflow: hidden;
  min-height: 520px;
  img{
    transition: all 0.3s ease-in-out;
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    &:hover{
      transform: scale(1.1);
    }
  }
}
.border-radius-1 {
  border-radius: 0.4rem;
}


@keyframes animate{

  0%{
   right: -100%;
  }
  100%{
    right: 0;
  }
}

.model{
  z-index: 9999999;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  transition: all 0.3s ease-in-out;
 

  .model-inner{
    position: absolute;
    right: -100%;
    top: 0;
    height: 100%;
    width: 60%;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    padding: 1rem;
    overflow-y: scroll;
    @media all and (max-width:768px){
      width: 75%  ;
    }
    
  }
  .animation{
    animation: animate 0.3s ease-in-out forwards;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  }

}

.list{
  margin-top: 1rem;
  ul{
    padding: 0;
    margin: 0;
    list-style: none;
    li{
      padding: 0.6rem 0;
     
      a{
        color: $color_black;
        text-decoration: none;      
        font-weight: bold;
        text-transform: capitalize;
        &:hover{
          color: $color_blue;
        }
      }
      ul{
        li{
          padding: 0.1rem 0;
          border-bottom: 1px solid #ccc ;
          a{
            font-weight: normal;
            padding-left: 0.5rem;
            font-size: 0.9rem;
          }
        }
      }
    }
  
    .inner-ul{
      padding-left: 0.5rem;
      li{
        &:last-child{
          border-bottom: 0;
        }
        &:first-child{
         border-top: 1px solid #ccc;
         margin-top: 1rem;
        }
      }
    }
  }
  }
  

  .nav-logo{
    max-width: 50px;
  }
  
  .nav-cross{
    font-size: 2.5rem;
  
  }

.footer-para{
  font-size: 0.7rem;
}

.social{
  margin-top: 1rem;
  ul{
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    li{
      width: 2.5rem;
      margin-right: 1rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: #eff2f6;
      display: flex;
      justify-content: center;
      align-items: center;
      a{
        text-decoration: none;
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;

        i{
          font-size: 1.3rem;
          color: #0D1A2A;
        }
      }
    }
  }
}